import React from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import * as styles from "./Emergency.module.scss"

function Emergency({ strapiData }) {
  return (
    <div className={styles.bets}>
      <Container>
        <h2
          className={`${styles.heading} `}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <p
          className={styles.description}
          // dangerouslySetInnerHTML={{
          //   __html: strapiData?.subTitle,
          // }}
        >
          Here’s how we cook half-baked projects and offer code rescue and
          recovery 
        </p>
        <Row className="gap-30 justify-content-center">
          {strapiData?.cards?.map((e, i) => (
            <Col lg={4} className={`${styles.colPad} gap-30`}>
              <div className={styles.card}>
                <div className={styles.plaImg}>
                  {/* <img
                    src={e?.image1[0]?.localFile?.publicURL}
                    // src="https://invozone-backend.s3.us-east-1.amazonaws.com/fi_681443_a23130a038.svg"
                    alt="tick"
                  /> */}
                  <lottie-player
                    autoplay
                    loop
                    src={e?.image1[0]?.localFile?.publicURL}
                    style={{ height: "60px", width: "60px", margin: "0" }}
                  />
                </div>
                <div>
                  <h3>{e?.title}</h3>
                  <p
                    className={styles.mainDes}
                    dangerouslySetInnerHTML={{
                      __html: e?.subTitle,
                    }}
                  />
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}

export default Emergency
